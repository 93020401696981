import { useMemo } from 'react';
import CardCarousel from 'common/components/carousel/CardCarousel';
import Engine1Card from './Engine1Card';
import Engine2Card from './Engine2Card';
import Engine4Card from './Engine4Card';

import { useGraphQLQuery, useReactQuery } from 'store/hooks';
import { GET_FUNDS_LIST } from 'common/hooks/investments/queries';
import { UserProfile } from 'store/user/types';
import RecommendationsSkeletonLoader from './SkeletonLoader/RecommendationsSkeletonLoader';

interface Props {
  className?: string;
  heading: string;
  tooltip?: string | React.ReactNode;
  user: UserProfile | null;
}

const Recommendations = ({
  className = 'container-lr-padding',
  heading,
  tooltip = '',
  user,
}: Props) => {
  const { data: clientList, isLoading: isClientListLoading } = useReactQuery(
    [`clientList${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/investors',
    },
  );

  const {
    data: { getFunds: { data: fundData } = { data: [] } } = {},
    isLoading: isLatestFundsLoading,
  } = useGraphQLQuery([`getFundList`], {
    baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
    query: GET_FUNDS_LIST,
    variables: {
      payload: {
        pageSize: 1,
        sortBy: 'last_updated_date',
      },
    },
  });

  const latestFund = useMemo(() => {
    if (Array.isArray(fundData) && fundData.length > 0) {
      return fundData[0];
    }
    return null;
  }, [fundData]);

  if (isLatestFundsLoading || isClientListLoading) return <RecommendationsSkeletonLoader />;

  return (
    <CardCarousel
      ctaText='Get More Recommendations'
      navLink='/recommendations'
      length={4}
      heading={heading}
      className={className}
      tooltip={tooltip}
      compare={!!latestFund}
      latestFund={latestFund}
    >
      {latestFund && <Engine4Card clientList={clientList} startIndex={0} latestFund={latestFund} />}
      {clientList[1] && <Engine2Card clientList={clientList} startIndex={1} />}
      {clientList[2] && (
        <Engine1Card
          clientList={clientList}
          startIndex={2}
          stats={{ portfolioSize: '$15.5M', alloc: '19.50%' }}
        />
      )}
      {clientList[3] && (
        <Engine2Card
          clientList={clientList}
          startIndex={3}
          stats={{
            esg: '18%',
            income: '15%',
            growth: '35%',
            div: '32%',
          }}
        />
      )}
    </CardCarousel>
  );
};

export default Recommendations;
