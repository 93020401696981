import axios from 'axios';

export const createHoldingPayload = (data: any, portfolioVal: any) => {
  const holdingsData = data?.map((item: any) => ({
    Ticker: item.Ticker,
    name: item.name,
    tableWeight: !isNaN(item.Amount / portfolioVal)
      ? ((item.Amount / portfolioVal) * 100).toFixed(2) + '%'
      : item.Weight,
    'market-value': item.Amount ? Number(item.Amount) : Number(item['market-value']),
    Weight: !isNaN(item.Amount / portfolioVal) ? item.Amount / portfolioVal : item.Weight,
  }));
  return holdingsData;
};

export const getRecommendationData = async ({
  data,
  age,
  portfolioVal,
  max_alt_alloc,
  recAllocation,
  isNonNaturalPerson,
}: any) => {
  const { data: respData } = await axios.post(
    'https://dev-recommendation-engine-dot-tifin-grow-dev.uc.r.appspot.com/getRecommendationAllocation' as string,
    {
      portfolio_ticker_weight: createHoldingPayload(data, portfolioVal),
      age: Number(age),
      is_non_natural_person: isNonNaturalPerson,
      port_dollar_amt: Number(portfolioVal),
      ...(max_alt_alloc && { max_alt_alloc: Number(max_alt_alloc) }),
      ...(recAllocation && { alt_alloc: Number(recAllocation) }),
    },
  );
  return respData;
};

export const getFundRecommendations = async ({
  selected_funds = [],
  ranked_factor = ['Return', 'Risk', 'Fee'],
}: any) => {
  const { data: respData } = await axios.post(
    'https://dev-recommendation-engine-dot-tifin-grow-dev.uc.r.appspot.com/getFundsComparisonData' as string,
    {
      selected_funds,
      ranked_factor,
    },
  );
  return respData;
};

export const getThemesData = async ({
  data,
  age,
  portfolioVal,
  max_alt_alloc,
  recAllocation,
  isNonNaturalPerson,
}: any) => {
  const { data: respData } = await axios.post(
    'https://dev-recommendation-engine-dot-tifin-grow-dev.uc.r.appspot.com/getThemeData' as string,
    {
      portfolio_ticker_weight: createHoldingPayload(data, portfolioVal),
      is_non_natural_person: isNonNaturalPerson,
      age: Number(age),
      port_dollar_amt: Number(portfolioVal),
      ...(max_alt_alloc && { max_alt_alloc: Number(max_alt_alloc) }),
      ...(recAllocation && { alt_alloc: Number(recAllocation) }),
    },
  );
  return respData;
};

export const getFundSpecificRecommendations = async ({
  data,
  age,
  portfolioVal,
  max_alt_alloc,
  recAllocation,
  isNonNaturalPerson,
  theme_wts = [],
  similar_bool = true,
}: any) => {
  const { data: respData } = await axios.post(
    'https://dev-recommendation-engine-dot-tifin-grow-dev.uc.r.appspot.com/getFundRecommendations' as string,
    {
      portfolio_ticker_weight: createHoldingPayload(data, portfolioVal),
      is_non_natural_person: isNonNaturalPerson,
      age: Number(age),
      port_dollar_amt: Number(portfolioVal),
      ...(max_alt_alloc && { max_alt_alloc: Number(max_alt_alloc) }),
      ...(recAllocation && { alt_alloc: Number(recAllocation) }),
      theme_wts: theme_wts,
      similar_bool: similar_bool,
    },
  );
  return respData;
};
