export const CREATE_USER = `
    mutation CreateUser(
      $userName: String
      $firstName: String
      $lastName: String
      $organisationId: String
      $phone: String
      $category: String
      $active: Boolean
      $teamsRoles: [teamsAndRolesInput]
    ) {
      createUser(
        user_name: $userName
        first_name: $firstName
        last_name: $lastName
        organisation_id: $organisationId
        phone: $phone
        category: $category
        active: $active
        teamsRoles: $teamsRoles
      ) {
        message
        user_id
        isokta
      }
    }
`;

export const UPDATE_USER = `
  mutation UpdateUser(
    $userId: String
    $firstName: String
    $lastName: String
    $phone: String
    $teamsRoles: [teamsAndRolesInput]
    $termsAndConditions: Boolean
  ) {
    updateUser(
      user_id: $userId
      first_name: $firstName
      last_name: $lastName
      phone: $phone
      teamsRoles: $teamsRoles
      termsAndConditions: $termsAndConditions
    ) {
      message
      user_id
      isokta
    }
  }
`;
