import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import LoadingButton from 'common/components/button/LoadingButton';
import MuiTable from 'common/components/table/Table';
import { handleGraphqlMutation } from 'helpers';
import { useLayoutEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { COLUMNS, ERROR_COLUMNS } from './constants';
import ErrorTable from '../../tables/Holdings/PreviewErrorTable';
import { StyledModal } from './style';
import UploadHoldingsConfirm from './UploadHoldingsConfirm';
import UploadHoldingsSuccess from './UploadHoldingsSuccess';

type Props = {
  selectedFile: any;
  incorrectDataList: any;
  acceptedFileData: any;
  isModalOpen: boolean;
  handleModalClose: any;
  isLoading: boolean;
};

const PreviewModal = ({
  incorrectDataList,
  acceptedFileData,
  isModalOpen,
  handleModalClose,
  isLoading,
  selectedFile,
}: Props) => {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const overflowValidRef = useRef(null);
  const overflowInvalidRef = useRef(null);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const userOnBehalfOf = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const UPLOAD_HOLDINGS = `
    mutation uploadHoldings($holdings: [saveHoldingsRequest]) {
      saveHoldings(holdings: $holdings)
    }
  `;
  const { mutate, isLoading: isUploading } = useMutation(
    (data: any): any => {
      return handleGraphqlMutation({
        url: process.env.REACT_APP_NODE_HOLDING_SERVER_BASE_URL as string,
        query: data.query,
        variables: { holdings: data.variables },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`getInvestors${user?.userId}`);
        // queryClient.invalidateQueries(`getHoldings${.clientId}`);
        handleModalClose();
        setUploadSuccess(true);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Holdings has been successfully uploaded.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const handleOnSubmitClick = () => {
    const apiData = [...acceptedFileData].map((item: any) => {
      const {
        account_name,
        fund_name,
        client_side_id,
        invalidInvestorName,
        invalidFundName,
        fund_name_API,
        account_name_API,
        ...rest
      } = item;

      rest.income = isNaN(Number(item.income)) ? null : item.income;
      rest.redemption = isNaN(Number(item.redemption)) ? null : item.redemption;
      rest.roc = isNaN(Number(item.roc)) ? null : item.roc;
      rest.subscription = isNaN(Number(item.subscription)) ? null : item.subscription;

      return rest;
    });
    mutate({
      query: UPLOAD_HOLDINGS,
      variables: apiData,
    });
  };

  const [shadows, setShadows] = useState<any>({
    left: false,
    right: true,
  });

  const [invalidShadows, setInvalidShadows] = useState<any>({
    left: false,
    right: true,
  });

  useLayoutEffect(() => {
    const validTable: HTMLElement | null = overflowValidRef?.current;

    if (validTable) {
      (validTable as HTMLElement)?.addEventListener('scroll', () => {
        if ((validTable as HTMLElement)?.scrollLeft === 0) {
          setShadows({ ...shadows, left: false });
        } else if (
          (validTable as HTMLElement)?.scrollLeft + (validTable as HTMLElement)?.offsetWidth ===
          (validTable as HTMLElement)?.scrollWidth
        ) {
          setShadows({ ...shadows, right: false });
        } else {
          setShadows({ ...shadows, left: true, right: true });
        }
      });
    }
    //eslint-disable-next-line
  }, [overflowValidRef, overflowValidRef?.current]);

  useLayoutEffect(() => {
    const invalidTable: HTMLElement | null = overflowInvalidRef?.current;

    if (invalidTable) {
      (invalidTable as HTMLElement)?.addEventListener('scroll', () => {
        if ((invalidTable as HTMLElement)?.scrollLeft === 0) {
          setInvalidShadows({ ...invalidShadows, left: false });
        } else if (
          (invalidTable as HTMLElement)?.scrollLeft + (invalidTable as HTMLElement)?.offsetWidth >=
          (invalidTable as HTMLElement)?.scrollWidth - 10
        ) {
          setInvalidShadows({ ...invalidShadows, right: false });
        } else {
          setInvalidShadows({ ...invalidShadows, left: true, right: true });
        }
      });
    }
    //eslint-disable-next-line
  }, [overflowInvalidRef, overflowInvalidRef?.current]);

  return (
    <>
      <StyledModal
        title='Preview Holdings'
        isModalOpen={isModalOpen}
        handleClose={handleModalClose}
        maxWidth='md'
        className='overrideMaxWidth'
        disableCloseIcon
      >
        {!isLoading && incorrectDataList?.length > 0 && (
          <Grid container mb={4}>
            <Grid item xs={12} mb={'20px'}>
              <h4 className='warn font-wt-400 mb-0'>
                The following rows could not be parsed. Please check for errors and upload again.
              </h4>
            </Grid>
            <Grid item xs={12}>
              <ErrorTable
                overflowRef={overflowInvalidRef}
                tableClassName={`errorTable ${invalidShadows.left ? 'leftShadow' : ''} ${
                  invalidShadows.right ? 'rightShadow' : ''
                }`}
                columns={ERROR_COLUMNS}
                rows={incorrectDataList}
              />
            </Grid>
          </Grid>
        )}
        {(isLoading || acceptedFileData?.length > 0) && (
          <Grid container mb={4}>
            {!isLoading && (
              <Grid item xs={12} mb={'20px'}>
                <h4 className='font-wt-400 mb-0'>The following data was successfully parsed.</h4>
              </Grid>
            )}
            <MuiTable
              tableClassName={` ${shadows.left ? 'leftShadow' : ''} ${
                shadows.right ? 'rightShadow' : ''
              }`}
              rows={acceptedFileData}
              columns={COLUMNS}
              overflowRef={overflowValidRef}
              isLoading={isLoading}
            />
          </Grid>
        )}

        <Grid item xs={12} container justifyContent='flex-end'>
          <MuiButton disabled={isUploading} buttonClick={() => handleModalClose()}>
            Discard Changes
          </MuiButton>
          {isUploading ? (
            <LoadingButton variant='contained' minWidth='150px' className='ml-3' />
          ) : (
            <MuiButton
              variant='contained'
              minWidth='150px'
              className='ml-3'
              buttonClick={() => setConfirmModalOpen(true)}
              disabled={acceptedFileData?.length === 0 || isLoading}
            >
              Update Holdings
            </MuiButton>
          )}
        </Grid>
      </StyledModal>
      <UploadHoldingsConfirm
        isModalOpen={confirmModalOpen}
        handleClose={() => setConfirmModalOpen(false)}
        buttonClick={() => {
          handleOnSubmitClick();
          setConfirmModalOpen(false);
        }}
      />

      <UploadHoldingsSuccess
        title={'Holdings Updated'}
        message={'Platform holdings have been updated with uploaded data.'}
        isModalOpen={uploadSuccess}
        handleClose={() => setUploadSuccess(false)}
      />
    </>
  );
};

export default PreviewModal;
