import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector, useGraphQLQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { Events as EventType, ReactQueryDataFetch } from 'common/types';
import Loading from 'common/components/Loading';
import Events from './Events';
import Funds from './Funds';
import Hero from './Hero';
import { UserProfile } from 'store/user/types';
import Themes from './Themes';
import { THEMES_DATA } from 'common/constants/betaData';
import { GET_FUND_THEMES_DATA, RECOMMENDED_FUND_QUERY } from './queries';
import Recommendations from './Recommendations';
import {
  setEngineNumber,
  setIsLandingPage,
  setRecommendationActiveStep,
  setRecommendationClient,
} from 'store/recommendation/slice';

const Intro = () => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [filteredEvents, setFilteredEvents] = useState<Array<EventType>>([]);
  const [themes, setThemes] = useState<Array<any>>([]);
  const showFunds =
    process.env.REACT_APP_SHOW_RECOMMENDED_FUNDS?.toString().toLowerCase() === 'true';

  const { data, isLoading: eventsLoading } = useReactQuery(
    [`dashboard${user?.userId}`],
    {
      url: 'qaip/v1/notificationmanagement/events/dashboard',
    },
    {
      retryOnMount: true,
      cacheTime: 0,
    },
  ) as { data: EventType[] } & ReactQueryDataFetch;

  useEffect(() => {
    if (data) {
      setFilteredEvents(
        data.filter(
          (event: EventType) =>
            new Date((event.endTime as string).replace(/-/g, '/')) >= new Date(),
        ),
      );
    }
  }, [data]);

  const { data: recommendedFunds } = useGraphQLQuery(
    'recommendedFunds',
    {
      baseURL: 'https://qualis-fund-api-dot-qualis-dev.uc.r.appspot.com',
      query: RECOMMENDED_FUND_QUERY,
      variables: {
        investerId: '',
        fundId: '',
        userId: user?.userId,
        categoryId: user?.category,
        organisationId: (user as any)?.organisationId,
      },
    },
    { enabled: !!user },
  );

  const { data: fundThemesData } = useGraphQLQuery(
    `fundThemesData`,
    {
      baseURL: process.env.REACT_APP_GRAPHQL_SERVER_BASE_URL as string,
      query: GET_FUND_THEMES_DATA,
    },
    { refetchOnMount: true },
  );

  useEffect(() => {
    if (fundThemesData && fundThemesData.getTheamesFundCount.length > 0) {
      setThemes(
        fundThemesData.getTheamesFundCount.map((t: any) => ({
          ...t,
          themeImageUrl: THEMES_DATA.find((d: any) => d.themeName === t.themeName)?.themeImageUrl,
        })),
      );
    }
  }, [fundThemesData]);

  useEffect(() => {
    dispatch(setEngineNumber({ engineNumber: 0 }));
    dispatch(setRecommendationActiveStep({ activeStep: 0 }));
    dispatch(setIsLandingPage({ isLandingPage: true }));
    dispatch(setRecommendationClient({ client: null }));
    //eslint-disable-next-line
  }, []);

  const isRecommendationEngineVisible =
    process.env.REACT_APP_SHOW_RECOMMENDATION_ENGINE_FLOW === 'true';

  if (!userProfile) return null;

  if (eventsLoading) return <Loading />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero user={user} />
      </Grid>

      {isRecommendationEngineVisible && (
        <Grid item container xs={12}>
          <Recommendations
            user={user}
            className='container-lr-padding'
            heading='Recommended for your clients'
          />
        </Grid>
      )}

      {themes?.length > 0 && (
        <Grid item container xs={12}>
          <Themes list={themes} />
        </Grid>
      )}

      {showFunds && (
        <Grid item container xs={12}>
          <Funds list={recommendedFunds?.getRecFunds} heading='Based On Your Activity' />
        </Grid>
      )}

      {filteredEvents?.length > 0 && (
        <Grid item container xs={12}>
          <Events
            list={filteredEvents.sort((a: EventType, b: EventType) =>
              (a.starTime as string).localeCompare(b.starTime as string),
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Intro;
