import { useAppSelector } from 'store/hooks';
import Footer from './layout/Footer';
import Header from './layout/Header';
import styled from '@emotion/styled';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { useEffect, useState } from 'react';
import { EntityType } from 'common/types';
import { UserProfile } from 'store/user/types';
import TermsOfUseModal from 'components/modals/TermsOfUseModal';
import ChatBot from 'components/chatBot';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import OktaSignInWidget from 'components/login/OktaLogin';
import { useOktaAuth } from '@okta/okta-react';
import WealthRedirectModal from 'components/modals/WealthRedirectModal';

export const PrivateRoute = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const location = window.location;
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const isDGUser = user?.category === EntityType.DEALERS_GROUP;
  const showChatBot = process.env.REACT_APP_SHOW_CHATBOT?.toString()?.toLowerCase() === 'true';
  useEffect(() => {
    if (userProfile && location.pathname === '/') {
      if (userOnBehalfOf) {
        if (userOnBehalfOf.category === EntityType.FUNDS_MANAGER) {
          navigate('/investments');
        }
      }
      if (!userOnBehalfOf) {
        if (userProfile.category === EntityType.FUNDS_MANAGER) {
          navigate('/investments');
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfile, userOnBehalfOf, location]);

  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(!user?.termsAndConditions);

  const handleTermsModalClick = (e: any, reason: string) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
    setTermsModalOpen(!user?.termsAndConditions);
  };

  return authState?.isAuthenticated &&
    (userProfile?.cognitoUserId || userProfile?.userOktaSubId) ? (
    <>
      <Header />
      <AuthBodyWrapper>
        <Outlet />
        <WealthRedirectModal />
        {!user?.termsAndConditions && isDGUser && (
          <TermsOfUseModal
            isModalOpen={termsModalOpen}
            setTermsModalOpen={setTermsModalOpen}
            handleModalClick={(e: any, reason: string) => handleTermsModalClick(e, reason)}
          />
        )}
      </AuthBodyWrapper>
      {showChatBot && isDGUser && <ChatBot name={user?.firstName} />}
      <Footer />
    </>
  ) : (
    <OktaSignInWidget />
  );
};

const AuthBodyWrapper = styled.div`
  min-height: calc(100vh - 102px);
`;
