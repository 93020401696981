import styled from '@emotion/styled';
import { Grid, Paper, Skeleton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import MuiCard from '../../common/components/card';
import MuiButton from '../../common/components/button';
import { canCreateOrder } from 'helpers/roles';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { formatAumValues } from 'helpers';
import { resetOrderData } from 'store/order/slice';
import { FundDetails } from 'common/types';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import { getEmoji } from 'helpers/investments';
import NewOrderDetailsModal from 'components/modals/NewOrderDetailsModal';
import DownlaodDocsIcon from 'common/assets/images/DocDownload.svg';
import PrefillDownloadModal from 'components/modals/PrefillDownloadModal';
import { docTypeDetails } from 'common/constants';
import { FundTypeClosedEnded } from './constants';
import { ReactComponent as CompareIcon } from 'common/assets/images/recommendation/Compare.svg';
import { ReactComponent as CompareSelected } from 'common/assets/images/recommendation/CompareSelected.svg';
import { selectFundsToCompare } from 'store/recommendation/selectors';
import { setFundsToCompare } from 'store/recommendation/slice';

const StyledCard = styled(MuiCard)`
  cursor: pointer;
  border: none;
  border-top: 1px solid var(--s15);
  border-radius: unset;
  position: relative;

  img,
  .MuiSkeleton-root {
    width: 100%;
    height: 70px;
    object-fit: contain;
  }

  h4.stat {
    font-size: 13px;
  }

  .assetClassCount {
    font-size: 0.5rem;
    border-radius: 50%;
    background-color: var(--s15);
    padding: 2px;
    margin-left: 2px;
    position: relative;
    bottom: 2px;
  }

  &.cardView {
    height: calc(100% - 44px);
    max-height: 450px;
    border: 1px solid var(--s15);
    border-radius: 0.5rem;
    padding: 22px 24px;
    h4.stat {
      font-size: 1rem;
    }
    .MuiPaper-root {
      padding: 0.5rem;
      background-color: #f2f2f2;
      .MuiGrid-item {
        padding: 0.5rem;
        &.pr-0 {
          padding-right: 0;
        }
      }
    }

    .compareContainer {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .compareBtn {
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        border-radius: 0.25rem;
        border: 1px solid var(--s15);
        &.disable {
          cursor: not-allowed;
        }
      }
    }

    &.compareSelected {
      border-color: var(--p150);

      .compareBtn {
        border-color: var(--p150);
        &.disable {
          cursor: pointer;
        }
      }
    }

    .desc {
      color: #102c44;
      font-size: 12px;
      line-height: 19px;
    }

    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: 76px;
    }

    img {
      object-position: left center;
    }

    img,
    .MuiSkeleton-root {
      width: 100px;
    }

    h5 {
      font-weight: 400;
      color: var(--p500);
      font-size: 14px;
    }

    &:hover {
      border-color: var(--p150);
    }
  }
`;

const DownloadBox = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  border-radius: 6px;
  padding: 10px;
  width: 39px;
  height: 39px;
  margin-left: 12px;
  z-index: 3;
  cursor: pointer;
  &:hover {
    border: 1px solid #4590d3;
  }
`;

interface Props {
  cardView: boolean;
  fund: FundDetails;
  setShowCompareModal: any;
}

const InvestmentCard = ({ cardView = true, fund, setShowCompareModal }: Props) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const fundsToCompare = useAppSelector(selectFundsToCompare);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [isAllocModalOpen, setIsAllocModalOpen] = useState<boolean>(false);
  const [isNewOrderModalOpen, setIsNewOrderModalOpen] = useState<boolean>(false);
  const [prefillModalOpen, setPrefillModalOpen] = useState<boolean>(false);
  const [subscriptionDocs, setSubscriptionDocs] = useState<any[]>([]);
  const isAddedToBasket = fundsToCompare.includes(fund?.fund_id);
  const isAllocationWorkflowEnabled =
    fund.is_allocation_workflow_enabled?.toLowerCase() === 'no' ? false : true;
  const [downloadBtnNotSelected, setDownloadBtnNotSelected] = useState<boolean>(true);
  const isRecommendationVisible = process.env.REACT_APP_SHOW_RECOMMENDATION_ENGINE_FLOW === 'true';

  const { mutate, data: logo } = useMutationQuery({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    params: { object_key: fund?.logo },
  });

  const navigateToFund = () =>
    navigate('/create-order', {
      state: {
        fundDetails: { ...fund, fundsId: fund.fund_id },
      },
    });

  const handleCardButtonClick = () => {
    dispatch(resetOrderData());
    if (isAllocationWorkflowEnabled) {
      setIsAllocModalOpen(!isAllocModalOpen);
    } else {
      setIsNewOrderModalOpen(!isNewOrderModalOpen);
    }
  };

  useEffect(() => {
    if (fund?.logo) mutate();
    // eslint-disable-next-line
  }, [fund]);

  const { data } = useReactQuery([`fundDetails_${fund.fund_id}`], {
    url: `qaip/v1/fundsmanagement/funds/id/${fund.fund_id}`,
  });

  const { data: documentData = [] } = useReactQuery(
    [`particularFundDocs${fund.fund_id}`],
    {
      url: `qaip/v1/documentmanagement/getList/fund?fund_id=${fund.fund_id}&document_type=file`,
    },
    {
      enabled: !!data,
    },
  );

  useEffect(() => {
    if (data) {
      mutate();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (documentData.length > 0) {
      const prefill = documentData.filter(
        (doc: any) => doc.docConfigTypeId === docTypeDetails[0].docConfigTypeId && !doc.isPrefill,
      );
      setSubscriptionDocs(prefill);
    }
    //eslint-disable-next-line
  }, [documentData]);

  const handleCardClick = () => {
    if (downloadBtnNotSelected) navigate(`/investment/${fund.fund_id}`);
  };

  const handleAddToCart = (selectedFund: string) => {
    const fundIds = fundsToCompare?.length > 0 ? [...fundsToCompare] : [];
    const isFilter = fundIds?.includes(selectedFund);
    setShowCompareModal(true);
    if (isFilter) {
      const filteredFunds = fundIds.filter((id) => id !== selectedFund);
      dispatch(setFundsToCompare({ fundsToCompare: filteredFunds }));
    } else if (fundsToCompare.length < 5) {
      const updatedCompareList = fundIds.concat(selectedFund);
      dispatch(setFundsToCompare({ fundsToCompare: updatedCompareList }));
    }
  };

  return (
    <Grid item xs={cardView ? 6 : 12} sx={{ maxHeight: cardView ? 'initial' : '180px' }}>
      <StyledCard
        minHeight='0'
        className={`${cardView ? 'cardView' : 'px-4'} ${isAddedToBasket ? 'compareSelected' : ''}`}
        onClick={handleCardClick}
      >
        <Grid
          container
          direction={cardView ? 'column' : 'row'}
          alignItems={cardView ? 'start' : 'center'}
          columnSpacing={cardView ? 0 : 4}
        >
          <Grid
            item
            xs={cardView ? 12 : 3}
            className={`${cardView ? 'mb-3 compareContainer' : ''}`}
          >
            {logo ? <img src={logo?.url} alt='' /> : <Skeleton variant='rectangular' />}
            {cardView && isRecommendationVisible && (
              <Tooltip
                title={
                  <div className='tooltipContainer'>
                    {isAddedToBasket
                      ? 'Remove From Compare'
                      : fundsToCompare?.length > 4
                      ? 'You can only compare up to 5 funds at a time'
                      : 'Add To Compare'}
                  </div>
                }
                placement='top'
                arrow
                classes={{ tooltip: 'p-0 tooltipParent' }}
              >
                <div
                  className={`compareBtn ${fundsToCompare?.length > 4 ? 'disable' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(fund.fund_id);
                  }}
                >
                  {isAddedToBasket ? <CompareSelected /> : <CompareIcon />}
                </div>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={cardView ? 12 : 6} alignSelf={cardView ? 'stretch' : 'auto'}>
            <h4 className='font-wt-400'>{fund?.fund_name}</h4>
            <Paper elevation={0}>
              <Grid container>
                {fund?.asset_class && (
                  <Grid item xs={4} className='pr-0'>
                    <h6 className='subtitle'>Asset Class</h6>
                    <h4 className='font-wt-400 mb-0 stat'>
                      {fund?.asset_class.split(',').length > 1 ? (
                        <>
                          {fund?.asset_class.split(',')[0]},
                          <span className='assetClassCount'>
                            +{fund?.asset_class.split(',').length - 1}
                          </span>
                        </>
                      ) : (
                        fund?.asset_class
                      )}
                    </h4>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <h6 className='subtitle'>MIN INVESTMENT</h6>
                  <h4 className='font-wt-400 mb-0 stat'>
                    ${Number(fund?.min_investment).toLocaleString()}
                  </h4>
                </Grid>
                {fund?.one_year_return &&
                fund?.one_year_return !== null &&
                !isNaN(fund?.one_year_return as any) ? (
                  <Grid item xs={4}>
                    <h6 className='subtitle'>1YR RETURN</h6>
                    <h4 className='font-wt-400 mb-0 stat'>
                      {fund?.one_year_return && fund?.one_year_return > 0
                        ? Number(fund?.one_year_return).toFixed(2) + '%'
                        : '--'}
                    </h4>
                  </Grid>
                ) : fund?.fund_assets && !isNaN(fund?.fund_assets as any) ? (
                  <Grid item xs={4}>
                    <h6 className='subtitle'>Fund AUM</h6>
                    <h4 className='font-wt-400 mb-0 stat'>
                      {fund?.fund_assets && !isNaN(fund?.fund_assets as any)
                        ? formatAumValues(fund?.fund_assets, 0)
                        : '--'}
                    </h4>
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <h6 className='subtitle'>Target Fund Size</h6>
                    <h4 className='font-wt-400 mb-0 stat'>
                      {fund?.target_fund_size && !isNaN(fund?.target_fund_size)
                        ? formatAumValues(fund?.target_fund_size, 0)
                        : '--'}
                    </h4>
                  </Grid>
                )}
                {!cardView && (
                  <Grid item xs={12} mt={2} className='flex-center-start'>
                    {fund?.fund_themes
                      ?.split(',')
                      .filter((str: string) => str !== '')
                      .map((theme: string, t: number) => (
                        <div key={`theme${t}`} className='fundTheme'>
                          {getEmoji(theme)}
                          {theme}
                        </div>
                      ))}
                    {fund.fund_type?.toLowerCase() === FundTypeClosedEnded.toLowerCase() && (
                      <div className='fundTheme'>
                        {getEmoji('Alloc')}
                        Time Limited Funds
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          {cardView && (
            <>
              <p className='desc mb-3'>{fund?.strategy_description?.replace(/<[^>]*>?/gm, '')}</p>
              <Grid item xs={12} mb={2} className='flex-center-start'>
                {fund?.fund_themes
                  ?.split(',')
                  .filter((str: string) => str !== '')
                  .map((theme: string, t: number) => (
                    <div key={`theme${t}`} className='fundTheme'>
                      {getEmoji(theme)}
                      {theme}
                    </div>
                  ))}
                {fund?.fund_type?.toLowerCase() === FundTypeClosedEnded.toLowerCase() && (
                  <div className='fundTheme'>
                    {getEmoji('Alloc')}
                    Time Limited Funds
                  </div>
                )}
              </Grid>
            </>
          )}
          <Grid
            item
            xs={cardView ? 12 : 3}
            alignSelf={cardView ? 'auto' : 'flex-start'}
            className={`${cardView ? '' : 'text-end'}`}
            container
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <MuiButton
              disabled={user && !canCreateOrder(user)}
              variant={`${isAllocationWorkflowEnabled ? 'outlined' : 'contained'}`}
              buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                handleCardButtonClick();
              }}
            >
              {isAllocationWorkflowEnabled ? 'Request Allocation' : 'Add New Order'}
            </MuiButton>
            <DownloadBox
              onClick={() => setPrefillModalOpen(true)}
              onMouseEnter={() => setDownloadBtnNotSelected(false)}
              onMouseLeave={() => setDownloadBtnNotSelected(true)}
            >
              <img style={{ height: '20px', width: '20px' }} src={DownlaodDocsIcon} alt='' />
            </DownloadBox>
          </Grid>
        </Grid>
      </StyledCard>
      <NewOrderDetailsModal
        isModalOpen={isNewOrderModalOpen}
        handleClose={() => setIsNewOrderModalOpen(!isNewOrderModalOpen)}
        buttonClick={navigateToFund}
      />
      <AllocationDetailsModal
        isModalOpen={isAllocModalOpen}
        handleClose={() => setIsAllocModalOpen(!isAllocModalOpen)}
        buttonClick={navigateToFund}
      />
      <PrefillDownloadModal
        isModalOpen={prefillModalOpen}
        handleClose={() => setPrefillModalOpen(false)}
        prefilledDocs={subscriptionDocs}
        allDocs={subscriptionDocs}
      />
    </Grid>
  );
};

export default InvestmentCard;
